import router from '@/router'
import {EventBus} from '@/handler/event-bus'

const session = {
  getSession(){
    var settings = localStorage.getItem(window.location.origin + '_settings')
    var auth = localStorage.getItem(window.location.origin + '_auth');
    var lastPage = localStorage.getItem(window.location.origin + '_last_page');
    var parameters = localStorage.getItem(window.location.origin + '_parameters');

    if(settings==undefined) {settings = null} else {settings = JSON.parse(settings)}
    if(auth==undefined) {auth = null} else {auth = JSON.parse(auth)}
    if(lastPage==undefined) {lastPage = null} else {lastPage = lastPage}
    if(parameters==undefined) {parameters = null} else {parameters = JSON.parse(parameters)}

    return {  settings: settings,
              auth: auth,
              lastPage: lastPage,
              parameters: parameters,}
  },
  getToken(){
    if(this.getSession().auth) {
      return "?token=" + this.getSession().auth.token
    } else {
      this.removeSessionAuth()
    }
  },
  setSessionSettings(data){
    localStorage.removeItem(window.location.origin + '_settings')

    var statusSettings = true
    for (let index = 0; index < 7; index++) {
      if (data.settings[index].value.length == 0) {
        statusSettings = false
      }
    }

    var data = {
      status: statusSettings,
      logo: data.settings[0].value,
      favicon: data.settings[1].value,
      image_login: data.settings[2].value,
      proyect_name: data.settings[3].value,
      color_primary: data.settings[4].value,
      color_title_menu: data.settings[5].value,
      color_select_menu: data.settings[6].value,
      image_watermark: data.settings[13].value,
    }

    localStorage.setItem(window.location.origin + '_settings', JSON.stringify(data))
    EventBus.$emit('Settings:settings', true)
  },
  setSessionAuth(data){
    localStorage.removeItem(window.location.origin + '_auth')
    localStorage.setItem(window.location.origin + '_auth', JSON.stringify(data))
    EventBus.$emit('Auth:auth', true)
  },
  setSessionLastPage(data) {
    localStorage.removeItem(window.location.origin + '_last_page')
    localStorage.setItem(window.location.origin + '_last_page', JSON.stringify(data))
  },
  setSessionParameters(data){
    localStorage.removeItem(window.location.origin + '_parameters')
    localStorage.setItem(window.location.origin + '_parameters', JSON.stringify(data))
    EventBus.$emit('Parameters:parameters', true)
  },
  removeSessionAuth(){
    localStorage.removeItem(window.location.origin + '_auth')
    router.push({ name: 'Init' });
  },
  removeSessionAuthTokenProblem(){
    this.setSessionLastPage(window.location.hash)
    localStorage.removeItem(window.location.origin + '_auth')
    router.push({ name: 'Init' });
  },
  removeSessionLastPage(){
    localStorage.removeItem(window.location.origin + '_last_page')
  },
  removeSessionParameters(){
    localStorage.removeItem(window.location.origin + '_parameters')
  },
  removeSessionAll(){
    localStorage.clear()
    sessionStorage.clear()

    router.push({ name: 'Init' });
  },
}

export default session;
